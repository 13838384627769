
export default function Project({img, url, title, desc}) {
    
    return (
        <div className='project-container'>
            <a href={url}>
                <img src={img} alt={desc}/>
                <h2>{title}</h2>
                <p>{desc}</p>
            </a>
        </div>
    )
}
