import footerLogo from '../assets/footer-logo.svg'


export default function Footer() {
    return (
        <div className='footer'>
        <div className="footer-quote-container">
            <img src={footerLogo} alt ="Footer Logo"/>
            <p className="footer-quote">
                Life is not easy. Life is not fair.<br/>
                But things are actually more rewarding
                when you put some sweat into them.
            </p>
            </div>
            <p className="copyright">© twenty twenty one Trilodi.com - Some Right Reserved</p>
        </div>
    )
}
