import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
import { useState } from 'react';
init("user_X5u42AEJnjoI2HzBVqNSu");

export default function GetInTouch() {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleChange = (e) =>{
        setEmail(e.target.value)
    }

    const handleMessageChange = (e) =>{
        setMessage(e.target.value)
    }

    const handleSubmit = (e) =>{
        e.preventDefault();


        emailjs.sendForm('service_upknk1n', 'trilodi_get_in_touch', e.target, 'user_X5u42AEJnjoI2HzBVqNSu')
          .then((result) => {
            document.querySelector('.emailSentNotice').style.height = "20vh";
            setTimeout(()=>{
                document.querySelector('.emailSentNotice').style.height = "0"
            }, 2000)
            setEmail('');
            setMessage('');
          }, (error) => {

          });
    }

    return (
        <div>
        
            <p className="form-subtitle">Drop a few lines so we can chit chat later</p>
            <form onSubmit={handleSubmit} className='contact-form'>
                <input name ="email" type='email'  className='custom-input' placeholder="Email" value={email} onChange={handleChange}/>
                <textarea name ="message" rows="10"  className='custom-input' placeholder="Message" value={message} onChange={handleMessageChange}></textarea>
                <button className="btn-blue">Send</button>
                <p className="emailSentNotice">Message Sent. Thank you</p>
            </form>
            
        </div>
   
    )
}
