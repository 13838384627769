
export default function Skill({icon, title, desc}) {
    
    return (
        <div className='skill-container' >
            <div className='skill-icon'>
                <img src={icon} alt='Front End Skills'/>
            </div>
            <div className="skill">
                <h2>{title}</h2>
                <p>{desc}</p>
            </div>
        </div>
    )
}
