
// import { useState } from 'react'
import { Grid3x2GapFill } from 'react-bootstrap-icons';
import logo from '../assets/logo.svg'
import Navigation from './partial/Navigation';

export default function Header() {

    const showHideNav = () =>{
        const  height = window.getComputedStyle(document.getElementById('header')).height === '70px' ? '230px' : '70px';
        document.getElementById('header').style.height = height;
    }

    window.onclick = () =>{
        if (window.getComputedStyle(document.getElementById('header')).height === '230px') {
            showHideNav();
        }
    }




    return (
        <div className="header" id='header' >
            <div className='header-logo'>
            
                <img onClick={()=>{window.scroll({ top: 0, behavior: "smooth" })}} src={logo} alt="Logo"/>
                <a className='emailTo' href="mailto:dan@trilodi.com">dan@trilodi.com</a>
            </div>
            <div className='burger-menu' onClick={showHideNav} > 
                <Grid3x2GapFill color="#35404e" size={35} />
            </div>
            
            <Navigation links = {[
                'Skills', 'Projects', 'Resume', 'Get In Touch'
                ]}/>
        </div>
    )
}
 