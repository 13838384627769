import { useEffect} from 'react'


import showcaseDroneImg from '../assets/showcase-drone.svg'
import Typewriter from 'typewriter-effect';
import { Parallax } from 'react-scroll-parallax';
 


export default function Showcase() {

    const randomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const moveDrone = () =>{
        const drone = document.getElementById("drone");
        const  transform = `
        rotate(${randomInt(-10, 10)}deg) 
        translateX(${randomInt(-50, 50)}%) 
        translateY(${randomInt(-50, 50)}%) 
        scale(${randomInt(5, 10)/10}) `;
        drone.style.transform = transform;
    }
    

    
    useEffect(() => {
        window.setInterval(moveDrone, 2000)
        moveDrone();
      });


    return (
        
        <div className='showcase-container'>
        <div className="showcase">
            <div className='showcase-content'>
                Hi, my name is 
                Vardan Melkumyan
                <br/>
                <p>I'm a&nbsp;</p> 

                <Typewriter
                options={{
                    strings: ['Developer','Designer', 'Dreamer'],
                    autoStart: true,
                    loop: true,
                }}
                />

        
                <p className='text-faded'>I genuinely enjoy bringing real world ideas to web in a modern & simple way</p>
            </div>
            <div className='showcase-image'>
            <Parallax className="custom-class" y={[-20, 40]} tagOuter="figure">
                <img id='drone' src= {showcaseDroneImg } alt='Scanning'/>
                </Parallax>
            </div>
            
        </div>
        </div>
      
    )
}
