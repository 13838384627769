// import logo from './logo.svg';
import './style/style.css';
import Header from './components/Header';
import Showcase from './components/Showcase';
import Section from './components/Section';
import Footer from './components/Footer.js';


function App() {
  return (
    <div >
    <Header/>
    <Showcase/>
    <Section title="My Skills" className="section" sectionName="skills"/>
    <Section title="Projects" className="section" sectionName="projects" />
    <Section title="Resume" className="section-flex" sectionName="resume" />
    <Section title="Get In Touch" className="section" sectionName="getInTouch" />
    <Footer/>
    </div>
  );
} 

export default App; 
