
// import { useEffect } from "react";
import {v4 as uuidv4} from 'uuid'

export default function Navigation({links}) {

    // const gotoSection = () =>{
    //     const section = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    //     section && scrollTo(document.getElementById(section))
    // }

    // window.onload = (e) => {
    //     e.preventDefault();
    //     gotoSection();
    // };

    const scrollTo = (e) =>{

        try {
            const {height} = document.getElementById('header').getBoundingClientRect();
            var topOfElement = e.offsetTop - height;
            window.scroll({ top: topOfElement, behavior: "smooth" });
        } catch (error) {
           
        }

    }
    
    const toCamelCase = str => {
        let s =
          str &&
          str
            .match(
              /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
            )
            .map(x => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
            .join('');
        return s.slice(0, 1).toLowerCase() + s.slice(1);
      };

    const linkList = links.map((link, index) => {
        const className = 'link';
        return <button 
        // href={`#${toCamelCase(link.toLowerCase())}`} 
        onClick={
            (e)=>{
                scrollTo(document.getElementById(toCamelCase(link.toLowerCase())))
                e.stopPropagation();
                // let stateObj = { id: "100" }; 
                // window.history.replaceState( link,`/${toCamelCase(link.toLowerCase())}`); 
                // window.location.href = (`${window.location.hostname}/${link}`)
            }
        } className={className} key={uuidv4()}>{link}</button>
    })

    return (
        <div className="navigation" id='navigation'>
            {linkList}
        </div>
    )
}
