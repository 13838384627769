
// Skills Section Imports
import Skill from "./Skill";
import frontEndIcon from '../assets/skills/front-end-skills-icon.svg' 
import backEndIcon from '../assets/skills/back-end-skills-icon.svg' 
import alsoIcon from '../assets/skills/also-skills-icon.svg' 

// Projects Section Imports
import Project from "./Project";
import paintBucketImg from '../assets/projects/paintBucketImg.png' 
import stlMastersImg from '../assets/projects/jsUrgentMedicalImg.png' 
import jsUrgentMedicalImg from '../assets/projects/stlMastersImg.png' 


// Resume Section Imports
import Resume from "./Resume";

// Get In Touch Section Imports
import GetInTouch from "./GetInTouch";


export default function Section({className, title, sectionName}) {



const renderContent = () =>{
    switch (sectionName) { 
        case 'skills': 
        return(
            <div className='skills' >
            <Skill icon={frontEndIcon} title="Front End" desc="HTML, JavaScript, CSS, Sass, React, jQuery, React Native"/>
            <Skill icon={backEndIcon} title="Back End" desc="PHP, Node.js, mySQL"/>
            <Skill icon={alsoIcon} title="Also.." desc="REST, GraphQL, Adobe Photoshop & Illustrator, Corel Draw, 3d Max"/>
            </div>
        );

        case 'projects': 
        return(
            <div className='projects'>
            <Project img={paintBucketImg} title ="updraw.co" url="https://updraw.co" desc="web app for creating posters & banners"/>
            <Project img={stlMastersImg} title ="jsurgentmedical.com" url="https://jsurgentmedical.com" desc="design & development of a static web app"/>
            <Project img={jsUrgentMedicalImg} title ="stlmasters.com" url="https://stlmasters.com" desc="wordpress theme design & customization"/>
            </div> 
        );


        case 'resume': 
        return(
            <>
            <Resume/>
            </> 
        );  
        
        case 'getInTouch': 
        return(
            <>
            <GetInTouch/>
            </> 
        );        
        

    
        default:
            return '';

    }
}
    return (
        
    
        <div className = {className} id={sectionName}>
            <p className="title">{title}</p>
            {renderContent()}
        </div>
    )
}


