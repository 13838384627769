
import  resume  from "../assets/files/vardanMelkumyanResume.pdf";

const downloadResume = (e) =>{
    window.open(resume);
}


export default function Resume() {

    return (
        <div className='resume-container'>
                <p>vardanMelkumyanResume.pdf(34kb)</p>
                <button onClick={downloadResume}  className="btn-blue mg-auto" >Download</button>
        </div>
    )
}
